<script setup lang="ts">

import type {CSSProperties} from "@vue/runtime-dom";
import { useSound } from '@vueuse/sound'
import click from '~/assets/sounds/click.mp3'
const { play } = useSound(click)
export interface ImageButtonProperties {
  url: string
  alt?: string
  circle?: boolean
  imageStyle?: CSSProperties,
  disabled?: boolean
}

const props = withDefaults(defineProps<ImageButtonProperties>(), {
  circle: false,
})

const emit = defineEmits(['click'])

const onClick = (event: MouseEvent) => {
  if (!props.disabled || event.altKey) {
    play()
    emit('click', event)
  }
}
</script>

<template>
  <div class="image-button">
    <div role="button" class="image-button__main" :class="{disabled}" @click="onClick($event)">
      <CircledImage v-if="circle" :url="url" :alt="alt" :shadow="true" :image-style="imageStyle" :shadow-offset="6"/>
      <img v-else :src="url" :alt="alt" :style="imageStyle"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.image-button {
  display: inline-block;
}

.image-button__main {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
</style>